@charset 'UTF-8';

$dir: rtl !default;

@import
  'abstracts/functions',
  'abstracts/variables',
  'abstracts/variables-base64',
  'abstracts/mixins';

@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/accessibility/accessibility.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/breadcrumb/breadcrumb.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/cat-nav/cat-nav.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/inner-banner/inner-banner.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/scroll-down/scroll-down.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/side-menu/side-menu.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/site-footer/site-footer-credit/site-footer-credit.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/site-footer/site-footer-form/site-footer-form.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/site-footer/site-footer-links/site-footer-links.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/site-footer/site-footer.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/site-header/site-header.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/blocks_shared/social-share/social-share-btns.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/layout/base-layout/base-layout.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/layout/page-layout/page-layout.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/layout/page-with-side-layout/page-with-side-layout.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/article/article-category-box/article-category-box.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/article/article-category.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/article/article-item-box/article-item-box.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/article/article-item.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/catalog/cat-box/cat-box.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/catalog/catalog-page.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/contact-us/form-elements.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/contact-us/landing/landing.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/content/content-page.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/gallery/gallery.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/homepage/homepage-slider/homepage-slider.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/homepage/homepage.scss";
@import "c:/xampp/htdocs/websites/wow-design/app/resources/pages/orders/cart.scss";
